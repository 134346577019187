























































import { Vue, Component } from 'vue-property-decorator';
import { Ax } from '@/utils';
import CTabs from './Tabs.vue';

const eventName = 'click';

interface IListVideo {
    href: string;
    src: string;
    title: string;
}

@Component({
    components: {
        'c-tabs': CTabs
    }
})

export default class Media extends Vue {
    private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);
    private __text = String(Date.now());
    private items: IListVideo[] = [];
    private visibleFilter = false;
    private messageSearch = String('');
    private galleryItem = '';

    // признак мобильности
    private isMobile = false;
    // открытие/закрытие верхнего меню с опциями
    private opened = false;

    private windowResizeEventHandler() {
        if (window.innerWidth <= 500) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    private columnClicked() {
        setTimeout(() => {
            this.opened = false;
        });
    }

    private get filteredItems(): IListVideo[] {
        const result: IListVideo[] = [];
        for (const i of this.items) {
            if (i.title.toUpperCase().includes(this.messageSearch.toUpperCase())) {
                result.push(i);
            }
        }
        return result;
    }

    // Фильтр
    private loadFilter() {
        if (this.messageSearch.length === 0) { return; }
        this.messageSearch = this.messageSearch;
    }

    private created() {
        this.windowResizeEventHandler();

        this.windowClickListener = () => {
            this.opened = false;
        };
        window.addEventListener('click', this.windowClickListener);
    }

    private beforeMount() {
        this.loadFavorites();
    }

    // Асинхронный запрос JSON данных
    private loadFavorites() {
        Ax<IListVideo[]>(
            {
                url: '/media/test data.json'
            },
            (data) => {
                //  console.log(data);
                //  console.log('!');
                this.items.splice(0, this.items.length);
                data.forEach((item) => {
                    //    console.log(item);
                    this.items.push(item);
                });
            }
            // (error) => {
            // console.error(error);
            //  }
        );
    }

    private tabsClick(data: string) {
        // console.log('tabs clicked, data:', data);
        if (data === 'favorite') {
            this.loadFavorites();
        }

        // фильтр
        if (data === 'filter') {
            if (this.visibleFilter === true) {
                this.visibleFilter = false;
            } else {
                this.visibleFilter = true;
                this.loadFilter();
            }
        } else {
            this.visibleFilter = false;
            this.messageSearch = '';
        }
    }

    private emitEvent(data: string) {
        this.galleryItem = data;
        this.$emit(eventName, data);
    }
}
