import { Component, Prop, Vue } from 'vue-property-decorator';

import { ISeda } from '../types';



@Component
export default class CContent extends Vue {
    @Prop({
        type: Number,
        default: 0
    })
    public sedasource!: number;
    @Prop({
        required: true,
        default: {}
    })
    public sedaData!: ISeda;
    public getEl(): HTMLDivElement | undefined {
        return this.$el as HTMLDivElement;
    }
}