














































































import Class from './c-content';
export default Class;
