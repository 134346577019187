











































































































































































































































































import {Vue, Component} from 'vue-property-decorator';
import * as Vue2Leaflet from 'vue2-leaflet';
import {latLng, Icon, icon} from 'leaflet';
import Vue2LeafletMarkercluster from './Vue2LeafletMarkercluster.vue';
import Vue2LeafletGeosearch from './v-geosearch/Vue2LeafletGeosearch.vue';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import axios from 'axios';
import {Ax} from '@/utils';
import CContent from './c-content';
import {ISeda} from './types';
import Media from '@/modules/media/Index.vue';
import mapCoord from '@/../public/map.json';
import store from "@/services/store";


const getFormattedDate = (date: Date): string => {
    const year = date.getFullYear();

    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
    return year + '-' + month + '-' + day;
};
delete (Icon.Default.prototype as unknown as any)._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
const provider = new OpenStreetMapProvider();
const searchControl = new GeoSearchControl({
    provider: provider
});

interface ICrimeSeverity {
    id: number;
    code: string;
    name_ru: string;
}

interface ICrime {
    id: number;
    code: string;
    name_ru: string;
}

@Component({
    components: {
        'l-map': Vue2Leaflet.LMap,
        'l-tilelayer': Vue2Leaflet.LTileLayer,
        'l-icondefault': Vue2Leaflet.LIconDefault,
        'l-marker': Vue2Leaflet.LMarker,
        'l-control-zoom': Vue2Leaflet.LControlZoom,
        'l-popup': Vue2Leaflet.LPopup,
        'l-control-lr': Vue2Leaflet.LControlLayers,
        'l-layer-group': Vue2Leaflet.LLayerGroup,
        'l-control-scale': Vue2Leaflet.LControlScale,
        'l-circlemarker': Vue2Leaflet.LCircleMarker,
        'l-marker-cluster': Vue2LeafletMarkercluster,
        'v-geosearch': Vue2LeafletGeosearch,
        'c-content': CContent,
        'media': Media
    }
})
export default class F extends Vue {
    private tab = 0;

    private locations: any[] = [];
    private lockind: any[] = [];
    private locpreschool: any[] = [];
    private locschool: any[] = [];
    private loctechn: any[] = [];
    private lochigh: any[] = [];
    private crimeData: any = {};
    private kinds: any = {};
    private preschool: any = {};
    private schools: any = {};
    private techns: any = {};
    private highs: any = {};
    private crimech = false;
    private allkind = true;
    private allcrime = false;
    private prescch = false;
    private schoolch = true;
    private technch = false;
    private highch = false;
    private sedavisible = false;

    private get locale(): string {
        return this.$i18n.locale;
    }

    private severitys: ICrimeSeverity[] = [];
    private crimes: ICrime[] = [];
    private seds: ISeda | null = null;
    private clickedSed: number | null = null;
    private visible = false;
    private icon = icon(
        Object.assign({}, Icon.Default.prototype.options, {iconUrl, shadowUrl})
    );
    private clusterOptions: any = {};

    private get geosearchOptions(): any {
        return {
            searchControl,
            provider,
            searchLabel: '',
            showPopup: true,
            keepResult: true,
            autoClose: true,
            autoPan: true
        };
    }

    private center = latLng(51.9, 70.33);
    private zoom = 7;
    private currentZoom = 7;
    private currentCenter = latLng(44.1, 53.8);
    private xmax = '50.0';
    public xmin = '40';
    public ymax = '75';
    public ymin = '70';
    private lat = latLng(this.center).lat;
    private lng = latLng(this.center).lng;
    public region = '23';

    private coordObject(regionID: any) {
        const that = this;
        mapCoord.forEach(function (item) {
            if (item.region == regionID) {
                that.xmax = item.xmax;
                that.xmin = item.xmin;
                that.ymax = item.ymax;
                that.ymin = item.ymin;
                that.center = latLng(item.xcenter, item.ycenter);
                that.zoom = item.zoom;
                that.xmax = item.xmax;
            }
        });
    }

    private dateBeg: Date = new Date().changeDate(-7);
    private dateEnd: Date = new Date();
    private dateB: Date = new Date(2018, 0, 1);
    private dateE: Date = new Date(2025, 0, 1);
    private options: Record<string, any> = {
        format: 'DD/MM/YYYY',
        useCurrent: false
    };

    private zoomUpdate() {
        this.currentZoom = this.zoom;
    }

    private centerUpdate() {
        this.currentCenter = this.center;
    }

    private valChanged(val: any) {
        if ((val === null) || (val === undefined)) {
            val = 0;
        }
        return val;
    }

    private check(atr: any) {
        switch (atr) {
            case 'crimech':
                this.allcrime = !this.crimech;
                break;
            case 'prescch':
                this.allkind = (this.schoolch && !this.prescch && this.technch && this.highch);
                break;
            case 'schoolch':
                this.allkind = (!this.schoolch && this.prescch && this.technch && this.highch);
                break;
            case 'technch':
                this.allkind = (this.schoolch && this.prescch && !this.technch && this.highch);
                break;
            case 'highch':
                this.allkind = (this.schoolch && this.prescch && this.technch && !this.highch);
                break;
            case 'allcrime':
                this.crimech = !this.allcrime;
                break;
            case 'allkind':
                this.schoolch = !this.allkind;
                this.prescch = !this.allkind;
                this.technch = !this.allkind;
                this.highch = !this.allkind;
                break;
            case 2:

                break;
        }
    }

    private crimeContent(val: any) {
        if (val.FZ1R18P5 === null || val.FZ1R18P5 === undefined) {
            val.FZ1R18P5 = '';
        }
        if (val.FZ1R18P6 === null || val.FZ1R18P6 === undefined) {
            val.FZ1R18P6 = '';
        }
        if (val.FE1R29P1_ID === null || val.FE1R29P1_ID === undefined) {
            val.FE1R29P1_ID = '';
        }
        if (val.FE1R32P1 === null || val.FE1R32P1 === undefined) {
            val.FE1R32P1 = '';
        }
        const str = '<div class= "csi--popup--head">'
            + '<div class= "csi--popup--header" > <span class= "csi--popup"> № КУИ:</span>  ' + val.UD
            + '</div>'
            + '</div>'
            + '<div class= "csi--popup--body">'
            + '<span class= "csi--popup"> Гос.орган: </span>' + val.ORGAN
            + '</br><span class= "csi--popup"> Статья: </span>' + this.crimeItems(val.CRIME_CODE) + '  ' + val.FE1R32P1
            + '</br><span class= "csi--popup"> Тяжесть: </span>' + this.shownItems(val.HARD_CODE)
            + '</br><span class= "csi--popup"> Дата возбужд. УД: </span>' + val.DAT_VOZB_STR
            + '</br><span class= "csi--popup"> Место совершения: </span>' + val.FE1R29P1_ID
            + '</br><span class= "csi--popup"> Улица: </span>' + val.FZ1R18P5
            + '</br><span class= "csi--popup"> Дом: </span>' + val.FZ1R18P6
            + '</div>';
        return str;
    }

    private tileProviders = [
        {
            name: 'Карта улиц',
            visible: true,
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        {
            name: 'Топографическая карта',
            visible: false,
            url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
            attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        }
    ];
    private popupContent: any = {};
    private defaultPopperProps = {
        popperOptions: {
            modifiers: {
                preventOverflow: {
                    padding: 20
                }
            },
            onUpdate: function (data: any) {
                //          console.log('JSON.stringify(data.attributes)', JSON.stringify(data.attributes));
            }
        }
    };

    private inputBegDate(val: Date) {
        if (this.dateBeg !== val) {
            this.dateBeg = val;
        }
    }

    private inputEndDate(val: Date) {
        if (this.dateEnd !== val) {
            this.dateEnd = val;
        }
    }

    private loadData() {
        this.visible = !this.visible;
        axios
            .get('/api/integration/qamqor/data?from='
                + getFormattedDate(this.dateBeg)
                + '&to='
                + getFormattedDate(this.dateEnd)
                + '&xmin=' + this.xmin + '&ymin=' + this.ymin + '&xmax=' + this.xmax + '&ymax=' + this.ymax)
            // + '&xmin=50.17&ymin=41.5&xmax=56.25&ymax=47.11')
            .then(response => {
                this.crimeData = response.data.features;
                this.locations = [];
                let i = 0;
                const loc: any[] = [];
                this.crimeData.forEach(function (element: any) {
                    i++;
                    loc.push({
                        id: i,
                        latlng: latLng(element.geometry.lat, element.geometry.lng),
                        attributes: element.attributes
                    });
                });
                this.locations = loc;
                this.visible = !this.visible;
            })
            .catch(reason => {
                console.error('Ошибка во время загрузки Преступности', reason);
            });
        axios
            .get(
                '/api/integration/seda/preschool?xmin=71.2&ymin=51.03&xmax=71.63&ymax=51.28'
            )
            .then(response => {
                this.preschool = response.data;
                this.locpreschool = [];
                let i = 0;
                const presc: any[] = [];
                this.preschool.forEach(function (element: any) {
                    i++;
                    presc.push({
                        id: i,
                        latlng: latLng(element.geometry.lat, element.geometry.lng),
                        attributes: element.attributes
                    });
                });
                this.locpreschool = presc;
            })
            .catch(reason => {
                console.error('Ошибка во время загрузки Садов', reason);
            });
        axios
            .get(
                '/api/integration/seda/school?xmin=51&ymin=75&xmax=51.73&ymax=7'
                // '/api/integration/seda/school/all_xy?xmin=75&ymin=51&xmax=51.73&ymax=7'
            )
            .then(response => {
                this.schools = response.data;
                this.locschool = [];
                let i = 0;
                const school: any[] = [];
                this.schools.forEach(function (element: any) {
                    i++;
                    school.push({
                        id: element.data.id,
                        latlng: latLng(element.geometry.lat, element.geometry.lng),
                        data: element.data
                    });
                });
                this.locschool = school;
            })
            .catch(reason => {
                console.error('Ошибка во время загрузки Школ', reason);
            });
        axios
            .get(
                '/api/integration/seda/technical?xmin=71.2&ymin=51.03&xmax=71.63&ymax=51.28'
            )
            .then(response => {
                this.techns = response.data;
                this.loctechn = [];
                let i = 0;
                const techn: any[] = [];
                this.techns.forEach(function (element: any) {
                    i++;
                    techn.push({
                        id: i,
                        latlng: latLng(element.geometry.lat, element.geometry.lng),
                        attributes: element.attributes
                    });
                });
                this.loctechn = techn;
            })
            .catch(reason => {
                console.error('Ошибка во время загрузки Колледжей', reason);
            });
        axios
            .get(
                '/api/integration/seda/higher?xmin=71.2&ymin=51.03&xmax=71.63&ymax=51.28'
            )
            .then(response => {
                this.highs = response.data;
                this.lochigh = [];
                let i = 0;
                const high: any[] = [];
                this.highs.forEach(function (element: any) {
                    i++;
                    high.push({
                        id: i,
                        latlng: latLng(element.geometry.lat, element.geometry.lng),
                        attributes: element.attributes
                    });
                });
                this.lochigh = high;
            })

            .catch(reason => {
                console.error('Ошибка во время загрузки ВУЗов', reason);
            });
    }

    private mounted() {
        this.region = store.state._instanceCode;

        this.coordObject(this.region);
        this.loadData();
        this.loadCrimeSeverity();
    }

    // Сброс настроек временного отрезка до 7-ми последних дней, включая текущую дату
    private resetToMap() {
        this.inputEndDate(new Date());
        this.inputBegDate(new Date().changeDate(-7));
        this.loadData();
        this.loadCrimeSeverity();
    }

    // Загрузка данных (кластера маркеров) после смены временного отрезка
    private showToMap() {
        this.loadData();
        this.loadCrimeSeverity();
    }

    private shownItems(code: string): string {
        let result = '';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let j = 0; j < this.severitys.length; j++) {
            if (code === this.severitys[j].code) {
                result = this.severitys[j].name_ru;
                break;
            }
        }
        return result;
    }

    private crimeItems(code: string): string {
        let result = '';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let j = 0; j < this.crimes.length; j++) {
            if (code === this.crimes[j].code) {
                result = this.crimes[j].code.slice(0, -1) + '. ' + this.crimes[j].name_ru;
                break;
            }
        }
        return result;
    }

    // запрос JSON данных справочника степеней тяжести преступлений
    private loadCrimeSeverity() {
        Ax<ICrimeSeverity[]>(
            {
                url: '/api/dict/crime_severity'
            },
            (data) => {
                this.severitys.splice(0, this.severitys.length);
                data.forEach((sevItem) => {
                    this.severitys.push(sevItem);
                });
            }
        );
        Ax<ICrime[]>(
            {
                url: '/api/dict/crime'
            },
            (data) => {
                this.crimes.splice(0, this.crimes.length);
                data.forEach((crItem) => {
                    this.crimes.push(crItem);
                });
            }
        );
    }

    // запрос JSON данных с аттрибутами учреждений образования (детские сады, школы, колледжи, ВУЗы)
    private loadSeda(id: number, urlAtr: number, lLng: any) {
        this.sedavisible = true;
        let urA = '';
        // eslint-disable-next-line default-case
        switch (urlAtr) {
            case 0:
                urA = '/api/integration/seda/preschool/';
                break;
            case 1:
                urA = '/api/integration/seda/school/';
                break;
            case 2:
                urA = '/api/integration/seda/technical/';
                break;
            case 3:
                urA = '/api/integration/seda/higher/';
                break;
        }
        Ax<ISeda>(
            {
                url: urA + id
            },
            (data) => {
                /*                if (data.langs !== null) {
                                    for (let i = 0; i < data.langs.length; i++) {
                                        if (data.langs[i] === '01') {
                                            data.langs[i] = 'ҚАЗ';
                                            break;
                                        }
                                    }
                                    for (let i = 0; i < data.langs.length; i++) {
                                        if (data.langs[i] === '02') {
                                            data.langs[i] = 'РУС';
                                            break;
                                        }
                                    }
                                    data.langs = JSON.stringify(data.langs).replace('[', '').replace(']', '').replace('"', '').replace('"', '').replace('"', '').replace('"', '');
                                }*/

                this.seds = data;
                this.clickedSed = id;
            }
        );
        this.sedavisible = false;
        this.lat = latLng(lLng).lat;
        this.lng = latLng(lLng).lng;
        if (this.zoom < 15) {
            this.center = latLng(this.lat + 0.018, this.lng);
        } else if ((this.zoom >= 15) && (this.zoom < 18)) {
            this.center = latLng(this.lat + 0.005, this.lng);
        } else if (this.zoom >= 18) {
            this.center = latLng(this.lat + 0.0005, this.lng);
        }
    }
}
