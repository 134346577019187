















































import { Vue, Component } from 'vue-property-decorator';

const eventName = 'click';

@Component
export default class Media extends Vue {
    // private awesome = true;
    private chosen = 'events-now';

    private emitEvent(data: string) {
        this.chosen = data;
        this.$emit(eventName, data);
    }
}
